$font: 'Open Sans', sans-serif;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    user-select: none;
    -webkit-user-drag: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-family: $font;
    font-size: 10px;
    &.font-small {
        font-size: 9px !important;
    }
}

body {
    font-size: 1.6rem;
}

html,
body {
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
}

img {
    display: block;
}

ul,
ol {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

button {
    cursor: pointer;
}
/** Change theme transition **/
:root {
    --transTime: 150ms;
    div {
        transition: background-color var(--transTime), background-image var(--transTime) border var(--transTime);
    }

    input,
    textarea,
    select,
    a {
        transition: background var(--transTime), border var(--transTime), color var(--transTime);
    }

    button {
        transition: background var(--transTime), border var(--transTime), color var(--transTime);
    }

    p,
    span {
        transition: background var(--transTime), color var(--transTime);
    }

    svg,
    path {
        transition: fill 0.1s, stroke 0.1s;
    }
}
